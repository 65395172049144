<template>
  <el-dialog :title="title" :visible="visible" :close-on-click-modal="false" width="1100px" @close="close">
    <el-form ref="editForm" :model="form" :rules="rules" :inline="true" label-position="right" label-width="100px"
      class="editForm">
      <el-form-item prop="ReturnCode" label="退货单号">
        <el-input disabled v-model="form.ReturnCode"></el-input>
      </el-form-item>
      <el-form-item prop="SupplierID" label="供应商">
        <el-select v-model="form.SupplierID" placeholder="请选择">
          <el-option v-for="(option, optionIndex) in (form.InitData || {})
            .SupplierList || []" :key="optionIndex" :label="option.label" :value="option.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="CreateTime" label="制单时间">
        <el-date-picker v-model="form.CreateTime" type="datetime" placeholder="选择制单时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item prop="ReturnDesc" label="退货描述">
        <el-input type="textarea" :rows="2" v-model="form.ReturnDesc" :style="{ width: '498px' }"></el-input>
      </el-form-item>
    </el-form>
    <div v-if="type == 'add' || type == 'edit'" class="operation">
      <el-button type="primary" @click="selectProduct">选择商品</el-button>
      <el-button type="primary" @click="deleteProduct">删除行</el-button>
    </div>
    <div></div>
    <div></div>
    <div>
      <grid-table ref="gridTable" :tableConfig="tableConfig" :showPagination="false" @selectionChange="selectionChange">
      </grid-table>
    </div>
    <product-selector ref="productSelector" v-if="productSelectorDialogVisible"
      @setProduct="setProduct"></product-selector>
    <div slot="footer" class="dialog-footer">
      <el-button v-if="type != 'detail'" type="primary" @click="save">保存</el-button>
      <el-button @click="close">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { successCode } from "@/config";
import { submit } from "@/api/base";
import ProductSelector from "@/views/product/base/components/ProductSelector.vue";

export default {
  name: "purchaseReturnOrderEdit",
  components: {
    ProductSelector,
  },
  data() {
    return {
      visible: false,
      type: "",
      title: "",
      form: {},
      detailColumns: [
        {
          label: "商品名称",
          prop: "PName",
          type: "text",
          width: "130px",
        },
        {
          label: "规格名称",
          prop: "SpecName",
          type: "text",
          width: "130px",
        },
        {
          label: "单价",
          prop: "Price",
          type: "text",
          width: "130px",
        },
        {
          label: "数量",
          prop: "ReturnNum",
          type: "text",
          width: "130px",
        },
        {
          label: "金额",
          prop: "TotalFee",
          type: "text",
          width: "130px",
        },
        {
          label: "退货原因",
          prop: "ResionName",
          type: "text",
          width: "130px",
        },
      ],
      editColumns: [
        {
          label: "商品名称",
          prop: "PName",
          type: "text",
          width: "130px",
        },
        {
          label: "规格名称",
          prop: "SpecName",
          type: "text",
          width: "130px",
        },
        {
          label: "单价",
          prop: "Price",
          type: "text",
          width: "130px",
        },
        {
          label: "数量",
          prop: "ReturnNum",
          type: "input",
          width: "130px",
        },
        {
          label: "退货原因",
          prop: "ResionID",
          type: "input",
          width: "130px",
        },
      ],
      rules: {
        ReturnCode: [{ required: true, trigger: "blur", message: "不可为空" }],
        SupplierID: [{ required: true, trigger: "blur", message: "请选择" }],
        CreateTime: [{ required: true, trigger: "blur", message: "不可为空" }],
      },
      tableConfig: {
        columns: [],
      },
      selectRows: [],
      productSelectorDialogVisible: false,
    };
  },
  methods: {
    init(type, purchaseReturnID) {
      this.visible = true;
      this.type = type;
      this.title = type == "add" ? "新增" : type == "edit" ? "编辑" : "详情";
      this.$nextTick(() => {
        this.fetchData(purchaseReturnID);
      });
    },
    async fetchData(purchaseReturnID) {
      const { data } = await submit("/api/purchaseBackGood/getInfo", {
        PurchaseReturnID: purchaseReturnID,
      });
      this.form = { ...data };
      if (this.form.SupplierID <= 0) {
        this.form.SupplierID = null;
      }
      if (this.type == "detail") {
        this.tableConfig.columns = this.detailColumns;
      } else {
        this.tableConfig.columns = this.editColumns;
      }
      if (purchaseReturnID > 0) {
        this.$refs.gridTable.setData(JSON.parse(this.form.DetailJson));
      }
    },
    selectionChange(selection) {
      this.selectRows = selection;
    },
    selectProduct() {
      if (!this.form.SupplierID || this.form.SupplierID == 0) {
        this.$baseMessage("请先选择供应商", "error");
        return;
      }
      this.productSelectorDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.productSelector.init();
      });
    },
    setProduct(rows) {
      let datas = [...this.$refs.gridTable.tableData];
      rows.forEach((row) => {
        if (!datas.find((data) => data.SpecID == row.SpecID)) {
          let data = { ...row };
          data.PurchaseNum = 1;
          datas.push(data);
        }
      });
      this.$refs.gridTable.setData(datas);
    },
    deleteProduct() {
      if (this.selectRows.length == 0) {
        this.$baseMessage("请选择行", "error");
        return;
      }
      this.$baseConfirm("确定选中行要删除吗？", null, () => {
        let datas = [...this.$refs.gridTable.tableData];
        this.selectRows.forEach((row) => {
          datas.forEach((data, index) => {
            if (data.SpecID == row.SpecID) {
              datas.splice(index, 1);
            }
          });
        });
        this.$refs.gridTable.setData(datas);
      });
    },
    save() {
      let productDatas = [...this.$refs.gridTable.tableData];
      if (productDatas.length == 0) {
        this.$baseMessage("商品不可为空", "error");
        return;
      }
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          let param = { ...this.form };
          param.DetailJson = JSON.stringify(productDatas);
          await submit(
            this.type == "add"
              ? "/api/purchaseBackGood/add"
              : "/api/purchaseBackGood/edit",
            param
          );
          this.$baseMessage(
            (this.type == "add" ? "新增" : "保存") + "成功",
            "success"
          );
          this.$emit("handleQuery");
          this.close();
        } else {
          return false;
        }
      });
    },
    close() {
      this.$refs.editForm.resetFields();
      this.$refs.gridTable.tableData = [];
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.editForm {
  border: 1px solid #ebeef5;
  padding-top: 10px;
  margin-bottom: 10px;
}

.operation {
  border: 1px solid #ebeef5;
  padding: 5px;
  margin-bottom: 10px;

  >div {
    display: inline-block;
    margin: 0 10px;
  }
}
</style>
