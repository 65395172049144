<template>
    <el-dialog v-dialog-drag title="选择商品" :visible="visible" :close-on-click-modal="false" width="1200px" @close="close"
        append-to-body>
        <el-container style="height:410px;">
            <el-aside width="200px;">
                <edit-tree treeTitle="商品分类" :treeData="treeData" @handleNodeClick="handleCateNodeClick"
                    style="width:200px;overflow:hidden;">
                </edit-tree>
            </el-aside>
            <el-main>
                <el-container>
                    <el-header height="35px">
                        <el-form label-position="right" label-width="80px" :inline="true">
                            <el-form-item label="">
                                <el-select v-model="productSearch.SearchField" style="width: 100px">
                                    <el-option label="商品货号" value="ItemNo"></el-option>
                                    <el-option label="商品名称" value="PName"></el-option>
                                    <el-option label="商品条码" value="Barcode"></el-option>
                                    <el-option label="规格编码" value="SpecSku"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="">
                                <el-input v-model="productSearch.SearchValue" style="width: 200px"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="searchProduct" style="width: 100px">
                                    搜索
                                </el-button>
                            </el-form-item>
                        </el-form>
                    </el-header>
                    <el-main>
                        <el-container>
                            <el-main>
                                <grid-table ref="productTable" :tableConfig="productTableConfig" :showCheckboxColumn="false"
                                    @handleRowClick="handleRowClick" height="300" style="width:100%;">
                                </grid-table>
                            </el-main>
                            <el-aside width="38%">
                                <div class="spec-title">
                                    商品规格
                                    <i class="el-icon-d-arrow-right"></i>
                                </div>
                                <grid-table ref="specTable" :tableConfig="specTableConfig" :showPagination="false"
                                    @selectionChange="handleSelectionChange">
                                </grid-table>
                            </el-aside>
                        </el-container>
                    </el-main>
                </el-container>
            </el-main>
        </el-container>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit">确定</el-button>
            <el-button @click="close">关闭</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { submit } from "@/api/base";

export default {
    name: "ProductSelector",
    data() {
        return {
            visible: false,
            productSearch: {
                SearchField: "ItemNo",
                SearchValue: ""
            },
            treeData: [],
            productTableConfig: {
                url: "/api/product/getList",
                rowKey: "ProductID",
                columns: [
                    {
                        label: "图片",
                        prop: "DefaultImage",
                        type: "img",
                        width: "100px",
                    },
                    {
                        label: "名称",
                        prop: "PName",
                        type: "text",
                        width: "130px",
                    },
                    {
                        label: "分类",
                        prop: "CateName",
                        type: "text",
                        width: "130px",
                    },
                    {
                        label: "条形码",
                        prop: "Barcode",
                        type: "text",
                        width: "130px",
                    },
                    {
                        label: "货号",
                        prop: "ItemNo",
                        type: "text",
                        width: "130px",
                    },
                    {
                        label: "品牌",
                        prop: "BrandName",
                        type: "text",
                        width: "130px",
                    },
                    {
                        label: "吊牌价",
                        prop: "Price",
                        type: "text",
                        width: "130px",
                    },
                    {
                        label: "网上售价",
                        prop: "UserPrice",
                        type: "text",
                        width: "130px",
                    },
                    {
                        label: "上架时间",
                        prop: "SaleTime",
                        type: "text",
                        width: "130px",
                    },
                ],
            },
            specTableConfig: {
                rowKey: "SpecID",
                columns: [],
            },
            selectRows: [],
        };
    },
    methods: {
        init() {
            this.visible = true;
            this.$nextTick(() => {
                this.search();
            });
        },
        async search() {
            if (this.$refs.productTable.searchParam) {
                this.$refs.productTable.searchParam["CateID"] = null;
            }
            const { data } = await submit("/api/product/category/getTree");
            this.treeData = data;
            this.$refs.productTable.fetchData();
        },
        searchProduct() {
            this.$refs.productTable.searchParam["SearchField"] = this.productSearch.SearchField;
            this.$refs.productTable.searchParam["SearchValue"] = this.productSearch.SearchValue;
            this.$refs.productTable.fetchData();
        },
        handleCateNodeClick(data) {
            this.$refs.productTable.searchParam["CateID"] = data.value;
            this.$refs.productTable.fetchData();
        },
        async handleRowClick(row) {
            const { data } = await submit(
                "/api/product/getProductSpec?productID=" + row.ProductID
            );
            this.specTableConfig.columns = data.columns;
            this.$refs.specTable.setData(data.datas);
        },
        handleSelectionChange(selection) {
            this.selectRows = selection;
        },
        submit() {
            this.$emit("setProduct", this.selectRows);
            this.close();
        },
        close() {
            this.visible = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.el-main {
    padding: 3px;
}

.spec-title {
    position: relative;
    height: 38px;
    line-height: 38px;
    padding-left: 5px;
    background: #3a8ee6;
    color: white;

    i {
        position: absolute;
        top: 10px;
        right: 5px;
        cursor: pointer;
    }
}
</style>
